import { AdminAccountQuery } from "graphql/query/adminAccount";
import { AdminUserQuery } from "graphql/query/adminUser";
import React, { createContext, useEffect, useReducer } from "react";
import { useQuery } from "react-apollo";

const user = JSON.parse(localStorage.getItem("authUser"))?.user;

export const UserContext = createContext({
  getUserDetailsFromLocalStorage: () => {},
  findRole: () => {},
  adminAccountName: "",
  user: null,
  isAdmin: false,
  isAdminPortal: undefined,
  adminId: null,
  admin: null,
  adminAccountId: null,
});

const GET_USER = "GET_USER";
const IS_ADMIN = "IS_ADMIN";
const ADMIN_ACCOUNT_NAME = "ADMIN_ACCOUNT_NAME";
const ADMIN = "ADMIN";

const initialState = {};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case GET_USER:
      return {
        ...state,
        user: payload,
        adminId: payload?.adminId,
        adminAccountId: payload?.adminAccountId,
      };
    case IS_ADMIN:
      return {
        ...state,
        isAdmin: payload,
      };
    case ADMIN_ACCOUNT_NAME:
      return {
        ...state,
        adminAccountName: payload?.shortName,
        adminAccountId: payload?.id,
      };
    case ADMIN:
      return {
        ...state,
        admin: payload,
      };

    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data: adminData, loading: adminLoading } = useQuery(AdminUserQuery, {
    variables: {
      adminId: user?.adminId,
    },
  });

  const admin = adminData?.admin[0];

  const { data, loading } = useQuery(AdminAccountQuery, {
    variables: {
      search: {
        id: {
          _eq: JSON.parse(localStorage.getItem("authUser"))?.user
            ?.adminAccountId,
        },
      },
    },
  });

  const isAdminPortal = data?.adminAccount[0]?.shortName === "DYSH";

  const getUserDetailsFromLocalStorage = () => {
    const user = JSON.parse(localStorage.getItem("authUser"))?.user;
    dispatch({ type: GET_USER, payload: user });
  };
 
  const findRole = () => {
    const adminType = JSON.parse(localStorage.getItem("authUser"))?.user
      ?.adminAccountType;
    const isAdmin = adminType === "ADMIN";
    dispatch({ type: IS_ADMIN, payload: isAdmin });
  };

  useEffect(() => {
    getUserDetailsFromLocalStorage();
    dispatch({
      type: ADMIN_ACCOUNT_NAME,
      payload: data?.adminAccount[0],
    });
    dispatch({
      type: ADMIN,
      payload: admin,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, adminLoading]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        getUserDetailsFromLocalStorage,
        findRole,
        isAdminPortal
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
