import gql from "graphql-tag";

export const QueryAContentDraft = gql`
  query queryAContentDraft($contentDraftId: uuid) {
    contentDrafts(where: { id: { _eq: $contentDraftId } }) {
      id
      title
      author
      description
      bookUrl
      imageUrl
      priceValue
      userId
      priceCurrency
      isPublished
      isExclusive
      isLoungeCover
      deepLink
      createdAt
      genre {
        id
        title
        createdAt
      }

      lounge {
        id
        name
        description
        createdAt
      }
    }
  }
`;
