import React, { useState, useContext } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { errorNotify, successNotify } from 'utils/toaster';
import { QueryABook } from '../../../graphql/a_book_query';
import { FlexDiv } from '../style';
import { UpdateBookMutation } from '../../../graphql/update/updateBook';
import {
	UpdateObjectionableContentMutation,
	UpdateReviewReplyObjectionableContentMutation,
	UpdateChatObjectionableContentMutation,
} from 'graphql/update/updateObjectionableContent';
import {
	UpdateReviewMutation,
	UpdateReviewReplyMutation,
	DeleteMessageForEveryone,
} from 'graphql/update/updateReview';
import { UserContext } from 'context/UserContext';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { SUSPEND_USER, USER_SUSPENSION_INFO } from 'graphql/delete/block_user';

const NewActionButton = ({
	actionText,
	actionLoading,
	actionStyle,
	previewUrl,
	action,
}) => {
	const [updateBook] = useMutation(UpdateBookMutation);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showObjectionModal, setShowObjectionModal] = useState(false);
	const [updateObjectionableContent] = useMutation(
		UpdateObjectionableContentMutation
	);
	const [updateReviewReplyObjectionableContent] = useMutation(
		UpdateReviewReplyObjectionableContentMutation
	);
	const [updateChatObjectionableContent] = useMutation(
		UpdateChatObjectionableContentMutation
	);
	const [deleteMessageForEveryone] = useMutation(DeleteMessageForEveryone);
	const [updateReview] = useMutation(UpdateReviewMutation);
	const [updateReviewReply] = useMutation(UpdateReviewReplyMutation);
	const [suspendUser] = useMutation(SUSPEND_USER);

	const { pathname, state } = useLocation();
	const { user } = useContext(UserContext);
	const { reviewId } = useParams();
	const path = pathname.split('/')[1];

	const { data } = useQuery(USER_SUSPENSION_INFO, {
		variables: {
			userId: reviewId,
		},
	});

	const isSuspended = data?.user[0]?.isSuspended;

	async function handleSuspension() {
		try {
			await suspendUser({
				variables: {
					suspended: isSuspended ? false : true,
					userId: reviewId,
				},
				refetchQueries: [
					{
						query: USER_SUSPENSION_INFO,
						variables: {
							userId: reviewId,
						},
					},
				],
			});
			setShowObjectionModal(false);
			successNotify(
				`User ${isSuspended ? 'unsuspended' : 'suspended'} successfully`
			);
		} catch (e) {
			console.log(e);
		}
	}

	async function toggleMode() {
		try {
			await updateBook({
				variables: {
					where: {
						id: { _eq: action?.book?.id },
					},
					set: { isPublished: action?.book?.isPublished ? false : true },
				},
				refetchQueries: [
					{
						query: QueryABook,
						variables: {
							bookId: action?.book?.id,
						},
					},
				],
			});
			setShowViewModal(false);
			successNotify(
				action?.book?.isPublished
					? 'Your content is hidden'
					: 'Your content is shown'
			);
		} catch (e) {
			setShowViewModal(false);
			errorNotify('Something went wrong, try again');
			console.log(e.message);
		}
	}

	async function handleObjection() {
		if (state.review) {
			try {
				await updateReview({
					variables: {
						reviewId,
						set: {
							deleted: true,
						},
					},
				});

				await updateObjectionableContent({
					variables: {
						reviewId,
						set: {
							reviewedAsObjectionable: true,
							reviewDate: new Date().toISOString(),
							reviewedByAdminId: user?.adminId,
						},
					},
				});

				setShowObjectionModal(false);
				successNotify('Reviewed as objectionable content');
				setTimeout(() => {
					window.location.href = '/reviews';
				}, 2000);
			} catch (error) {}
		}

		if (state.reviewReply) {
			try {
				await updateReviewReply({
					variables: {
						reviewReplyId: reviewId,
						set: {
							deleted: true,
						},
					},
				});

				await updateReviewReplyObjectionableContent({
					variables: {
						reviewReplyId: reviewId,
						set: {
							reviewedAsObjectionable: true,
							reviewDate: new Date().toISOString(),
							reviewedByAdminId: user?.adminId,
						},
					},
				});

				setShowObjectionModal(false);
				successNotify('Reviewed as objectionable content');
				setTimeout(() => {
					window.location.href = '/reviews';
				}, 2000);
			} catch (error) {
				console.log(error);
			}
		}

		if (state.chat) {
			try {
				await deleteMessageForEveryone({
					variables: {
						chatId: reviewId,
					},
				});

				await updateChatObjectionableContent({
					variables: {
						chatId: reviewId,
						set: {
							reviewedAsObjectionable: true,
							reviewDate: new Date().toISOString(),
							reviewedByAdminId: user?.adminId,
						},
					},
				});

				setShowObjectionModal(false);
				successNotify('Reviewed as objectionable content');
				setTimeout(() => {
					window.location.href = '/reviews';
				}, 2000);
			} catch (error) {
				console.log(error);
			}
		}
	}

	return (
		<>
			{action && (
				<FlexDiv borderBottom>
					{actionText?.btn1Text && (
						<>
							<a
								href={previewUrl}
								target='_blank'
								rel='noopener noreferrer'
								style={{ color: '' }}
								className='text-info btn btn-outline-info w-100'
							>
								{actionText?.btn1Text}
							</a>
							<div style={{ margin: '0 10px' }}></div>
						</>
					)}

					{actionText?.btn2Text &&
						pathname !== '/create-book' &&
						path !== 'review' && (
							<>
								<Button
									color={`${
										actionStyle?.btn2Style?.background
											? actionStyle?.btn2Style?.background
											: 'warning'
									} w-100`}
									outline
									className='waves-effect waves-light'
									onClick={() => setShowViewModal(true)}
								>
									{actionLoading?.btn2Loading && (
										<Spinner
											style={{ width: 12, height: 12, marginRight: 10 }}
										/>
									)}
									{actionText?.btn2Text}
								</Button>
								<div style={{ margin: '0 10px' }}></div>
							</>
						)}

					{actionText?.btn2Text && path === 'review' && (
						<>
							<Button
								color={`${
									actionStyle?.btn2Style?.background
										? actionStyle?.btn2Style?.background
										: 'warning'
								} w-100`}
								outline
								className='waves-effect waves-light'
								onClick={() => setShowObjectionModal(true)}
							>
								{actionLoading?.btn2Loading && (
									<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
								)}
								{actionText?.btn2Text}
							</Button>
							<div style={{ margin: '0 10px' }}></div>
						</>
					)}

					{actionText?.btn2Text && pathname === '/create-book' && (
						<>
							<Button
								color={`${
									actionStyle?.btn2Style?.background
										? actionStyle?.btn2Style?.background
										: 'warning'
								} w-100`}
								outline
								className='waves-effect waves-light'
								onClick={action?.btn2}
							>
								{actionLoading?.btn2Loading && (
									<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
								)}
								{actionText?.btn2Text}
							</Button>
							<div style={{ margin: '0 10px' }}></div>
						</>
					)}
					{actionText?.btn3Text && (
						<>
							<Button
								color={`${
									actionStyle?.btn3Style?.background
										? actionStyle?.btn3Style?.background
										: 'primary'
								} w-100`}
								outline
								className='waves-effect waves-light'
								onClick={action?.btn3}
							>
								{actionLoading?.btn3Loading && (
									<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
								)}
								{actionText?.btn3Text}
							</Button>
							<div style={{ margin: '0 10px' }}></div>
						</>
					)}
					{actionText?.btn4Text && (
						<>
							<Button
								color='success w-100'
								outline
								className='waves-effect waves-light'
								onClick={action?.btn4}
							>
								{actionLoading?.btn3Loading && (
									<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
								)}
								{actionText?.btn4Text}
							</Button>
						</>
					)}
				</FlexDiv>
			)}
			<Modal isOpen={showViewModal} centered={true}>
				<ModalBody>
					<p>
						Are you sure you want to{' '}
						{action?.book?.isPublished ? 'hide' : 'show'} {action?.book?.title}{' '}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setShowViewModal(false)}>
						No
					</Button>{' '}
					<Button color='primary' onClick={toggleMode}>
						Yes
					</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={showObjectionModal} centered={true}>
				<ModalBody>
					<p>
						{state?.user
							? `Are you sure you want to ${
									isSuspended ? 'unsuspend' : 'suspend'
							  } reported user from dysh platform?`
							: 'Are you sure you want to review as objectionable?'}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						color='secondary'
						onClick={() => setShowObjectionModal(false)}
					>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={() => {
							if (state?.user) {
								handleSuspension();
							} else {
								handleObjection();
							}
						}}
					>
						Yes
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default NewActionButton;
