import gql from 'graphql-tag';

export const QueryABook = gql`
	query getABookQuery($bookId: uuid) {
		book(where: { id: { _eq: $bookId } }) {
			id
			title
			author
			description
			bookUrl
			imageUrl
			priceValue
			userId
			priceCurrency
			isPublished
			isWebReadable
			isExclusive
			isLoungeCover
			publishedBy
			deepLink
			createdAt
			genres {
				genre {
					id
					title
					createdAt
				}
			}
			lounges {
				lounge {
					id
					name
					description
					createdAt
				}
			}
			reviews {
				objectionableContents {
					id
					user {
						id
						username
						firstName
						lastName
						avatarUrl
					}
					raisedByUserId
					reason
				}
			}
			owner {
				id
				isAdmin
				username
				avatarUrl
				email
				firstName
				lastName
			}
			readers(order_by: { createdAt: desc }) {
				id
				user {
					id
					username
					avatarUrl
					email
					firstName
					lastName
				}
				readingProgress
			}
			highlights {
				id
				comment
				highlightedText
				user {
					username
					firstName
					lastName
					avatarUrl
				}
			}
			readStats {
				readCount
			}
		}
	}
`;
