import React from "react";
// import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

import { Colors } from "assets/colors/Colors";
import dyshLogoImage from "assets/images/dysh/dysh-app-icon.png";
import { Spinner } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

const DetailLayoutView = ({
  image,
  onChange,
  uploadName = "Lounge",
  group,
  children,
  imageLoading,
  breadcrumbTitle,
  breadcrumbItem,
  breadcrumbRoute,
  hideImage = false,
}) => {
  return (
    <Container>
      {breadcrumbTitle && (
        <div className="px-5 mt-3">
          <Breadcrumbs
            title={breadcrumbTitle ?? ""}
            breadcrumbItem={breadcrumbItem ?? ""}
            route={breadcrumbRoute ?? ""}
          />
        </div>
      )}
      <Row grid={hideImage ? "1fr" : "1fr 1fr"}>
        {!hideImage && (
          <Col>
            <div className="image">
              <img
                src={image ? image : dyshLogoImage}
                alt="lounge/group upload"
                width="100%"
              />
              {!group &&
                (imageLoading ? (
                  <div className="label">
                    <Spinner />
                  </div>
                ) : (
                  <label htmlFor="upload" className="label">
                    Upload {uploadName} Image
                    <input id="upload" type="file" onChange={onChange} />
                  </label>
                ))}
            </div>
          </Col>
        )}
        <Col>
          <div
            style={{
              height: "90vh",
              background: "white",
              overflowY: "auto",
              width: "100%",
            }}
          >
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export const Container = styled.div`
  height: 90vh;
  overflow: hidden;
`;

export const Loader = styled.div`
  max-width: 150px;
  height: 150px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
export const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => (grid ? grid : "1fr 1fr")};
  width: 100%;
  height: 90vh;
  padding: 0 20px;
  overflow: hidden;
`;
export const Col = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  /* background-color: ${Colors.dyshYellow}; */

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    /* background-color: orange; */
    /* display: table-cell;
    vertical-align: middle;
    text-align: center; */
    display: flex;
    align-items: center;

    /* img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
    } */

    .label {
      background-color: ${Colors.white};
      border-radius: 4px;
      border: 1px solid ${Colors.gray500};
      color: ${Colors.default};
      font-size: 12px;
      padding: 10px 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    input {
      display: none;
    }
  }
`;

export default DetailLayoutView;
