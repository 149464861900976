import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { DetailLayoutView } from 'components/DetailView';
import { QueryABook } from '../../graphql/a_book_query';
import CustomSpinner from 'ui/CustomSpinner';
import { UpdateBookMutation } from 'graphql/update/updateBook';
import { Config } from '../../config';
import { errorNotify, successNotify } from 'utils/toaster';
import NewDetailView from 'components/DetailView/NewDetailScreen';
import { getExtName } from 'utils/getExtName';
import { pluralizeText } from 'utils/pluralizeText';
import { formatPrice } from 'utils/formatPrice';
import { priceCurrencyOptions } from './static/currency';
import { UserContext } from 'context/UserContext';
import { RemoveContentDraftsMutation } from 'graphql/delete/remove_content_drafts';
import { QueryAContentDraft } from 'graphql/query/getAContentDraft';
import { AddBookGenreMutation } from 'graphql/insert/insert_book_genre';
import { AddBookToLoungeMutation } from 'graphql/insert/insert_lounge_book';
import { AddBookMutation } from 'graphql/insert/insert_book';
import { LoungePortalContext } from 'context/LoungePortalContext';
import { RemoveExistingCover } from 'graphql/update/remove_book_cover';
import { ArchiveContentMutation } from 'graphql/insert/archive_content';
import { RemoveBookMutation } from 'graphql/delete/remove_book';
import { UpdateContentDraft } from 'graphql/update/updateContentDrafts';

const exclusiveId = '';

const NewBookDetail = () => {
	const { bookId } = useParams();
	const { loungeId } = useContext(LoungePortalContext);

	const history = useHistory();
	const { isAdmin, user, admin } = useContext(UserContext);

	const [preview, setPreview] = useState();
	const [bookUrlFile, setBookUrlFile] = useState();
	const [draftLoading, setDraftLoading] = useState(false);
	const [publishLoading, setPublishLoading] = useState(false);

	const [values, setValues] = useState({
		title: '',
		author: '',
		description: '',
		priceValue: 0,
		priceCurrency: 'gbp',
		loungeQuery: '',
		genreQuery: '',
		isWebReadable: '',
		exclusiveId: null,
		isExclusive: '',
		isLoungeCover: '',
		deepLink: undefined
	});

	const [file, setFile] = useState();
	const [publishModal, setPublishModal] = useState(false);
	const [updateModal, setUpdateModal] = useState(false);
	const [draftModal, setDraftModal] = useState(false);

	const { data: bookData, loading: bookLoading } = useQuery(QueryABook, {
		variables: {
			bookId,
		},
	});

	const { data: contentDraftData, loading: contentDraftLoading } = useQuery(
		QueryAContentDraft,
		{
			variables: {
				contentDraftId: bookId,
			},
		}
	);

	const [updateBook] = useMutation(UpdateBookMutation);
	const [updateContentDraft] = useMutation(UpdateContentDraft);
	const [addBookGenre] = useMutation(AddBookGenreMutation);
	const [addBookToLounge] = useMutation(AddBookToLoungeMutation);
	const [addBook] = useMutation(AddBookMutation);
	const [archiveContent, { loading: archiveContentLoading }] = useMutation(
		ArchiveContentMutation
	);
	const [removeBook, { loading: removeBookLoading }] =
		useMutation(RemoveBookMutation);
	const [removeContentDraft, { loading: removeContentDraftLoading }] =
		useMutation(RemoveContentDraftsMutation);
	const [removeExistingBookCover] = useMutation(RemoveExistingCover);

	const book = bookData?.book[0] ?? contentDraftData?.contentDrafts[0];
	const readersCount = bookData?.book[0]?.readers?.length;
	const [publishedStatus, setPublishedStatus] = useState();
	const [priceObj, setPriceObj] = useState();

	useEffect(() => {
		setValues({
			title: book?.title,
			author: book?.author,
			description: book?.description,
			priceValue: book?.priceValue,
			priceCurrency: book?.priceCurrency,
			isWebReadable: book?.isWebReadable,
			isExclusive: book?.isExclusive,
			isLoungeCover: book?.isLoungeCover,
			deepLink: book?.deepLink
		});

		setPublishedStatus(book?.isPublished ? 'Live' : 'Draft');
		setPriceObj({
			priceValue: book?.priceValue,
			priceCurrency: book?.priceCurrency,
		});

		// eslint-disable-next-line
	}, [bookLoading, contentDraftLoading]);

	const handleFile = async ({ target: { files } }) => {
		const imageSizeLimit = 500;
		const reader = new FileReader();

		if (files) {
			reader.readAsDataURL(files[0]);
		}

		const imageSize = files[0]?.size / 1024;

		if (imageSize > imageSizeLimit) {
			return errorNotify(
				'The size of the image should be less than equal to 500kb'
			);
		}
		setFile(files[0]);

		reader.onloadend = function (e) {
			setPreview(reader.result);
			// const image = new Image();
			// image.src = e.target?.result;

			// image.onload = function () {
			//   if (!(this.height > this.width + 200)) {
			//     return errorNotify("Please upload a portrait image");
			//   }
			//   setImageUrlFile(files[0]);
			// };
		};
	};

	const handleContentUpload = async ({ target: { files } }) => {
		const extName = getExtName(files[0]);
		const contentSize = files[0]?.size / 1000;
		const max = 3000;
		if (contentSize > max) {
			return errorNotify('Content size should be less than 3mb');
		}
		if (!(extName === 'pdf' || extName === 'epub' || extName === 'png')) {
			return errorNotify('Please upload a valid content');
		}
		setBookUrlFile(files[0]);
	};

	const handleUpdateContent = async () => {
		let res = {};
		const {
			title,
			author,
			description,
			isWebReadable,
			isExclusive,
			isLoungeCover,
			priceValue,
			priceCurrency,
			deepLink,
		} = values;
		try {
			setPriceObj({
				...priceObj,
				priceCurrency: priceCurrency?.value,
				priceValue: priceValue?.value,
				isWebReadable: isWebReadable?.value,
			});
			setPublishLoading(true);
			const form = new FormData();
			form.append('bookUrl', bookUrlFile);
			form.append('imageUrl', file);

			let bookUrl;
			let imageUrl;

			if (bookUrlFile) {
				res = await fetch(
					`${Config.restApiUrl}/uploads/content/update-book-url`,
					{
						method: 'POST',
						'Content-type': 'multipart/form-data',
						body: form,
					}
				);

				const response = await res.json();
				bookUrl = response?.data?.bookUrl;
			}

			if (file) {
				const res = await fetch(
					`${Config.restApiUrl}/uploads/content/update-book-image-url`,
					{
						method: 'POST',
						'Content-type': 'multipart/form-data',
						body: form,
					}
				);

				const response = await res.json();
				imageUrl = response?.data?.imageUrl;
			}

			if (isLoungeCover?.value) {
				await removeExistingBookCover({
					variables: {
						loungeId,
					},
				});
			}

			if (book?.isPublished) {
				const response = await updateBook({
					variables: {
						where: { id: { _eq: book?.id } },
						set: {
							title,
							author,
							description,
							priceCurrency: priceCurrency?.value,
							priceValue,
							imageUrl,
							bookUrl,
							userId: exclusiveId ? exclusiveId : null,
							isWebReadable: isWebReadable?.value,
							isExclusive: isExclusive?.value,
							isLoungeCover: isLoungeCover?.value,
							isPublished: true,
							deepLink
						},
					},
				});

				setPublishedStatus('Live');
				setPublishLoading(false);
				const msgVar = response?.data?.update_book?.returning[0]?.title;
				successNotify(`Successfully publish ${msgVar} content.`);
				window.location.reload();
			} else {
				const {
					title,
					author,
					description,
					bookUrl,
					imageUrl,
					isWebReadable,
					priceValue,
					priceCurrency,
					lounge: { id: loungeId },
					genre: { id: genreId },
				} = book;
				const res = await addBook({
					variables: {
						objects: {
							title,
							author,
							description,
							bookUrl,
							imageUrl,
							isWebReadable,
							isExclusive,
							isLoungeCover,
							priceValue,
							priceCurrency,
							publishedBy: `${user.firstName} ${user.lastName}`,
							// userId: exclusiveId ? exclusiveId : null,
						},
					},
				});

				const bookId = res?.data?.insert_book?.returning[0]?.id;

				await addBookGenre({
					variables: {
						objects: {
							genreId,
							bookId,
						},
					},
				});

				await addBookToLounge({
					variables: {
						objects: {
							loungeId,
							bookId,
						},
					},
				});

				await removeContentDraft({
					variables: {
						contentDraftId: book?.id,
					},
				});

				const successMessage = `${res?.data?.insert_book?.returning[0]?.title} added successfully`;
				history.push(isAdmin ? '/contents' : '/content-owner');
				successNotify(successMessage);
				window.location.reload();
			}
		} catch (error) {
			console.info('Book Update error', error);
			setPublishLoading(false);
			errorNotify('Something went wrong, try again');
		}
	};

	const draftBookUpload = async (formData) => {
		let bookResponse = await fetch(
			`${Config.restApiUrl}/uploads/content/update-book-url`,
			{
				method: 'POST',
				'Content-type': 'multipart/form-data',
				'x-amz-acl': 'public-read',
				body: formData,
			}
		);
		const response = await bookResponse.json();
		return response.data.bookUrl;
	};

	const draftBookImageUpload = async (formData) => {
		const imageResponse = await fetch(
			`${Config.restApiUrl}/uploads/content/update-book-image-url`,
			{
				method: 'POST',
				'Content-type': 'multipart/form-data',
				'x-amz-acl': 'public-read',
				body: formData,
			}
		);
		const response = await imageResponse.json();
		return response.data.imageUrl;
	};

	const draftMultipleFileUpload = async (formData) => {
		const multiResponse = await fetch(
			`${Config.restApiUrl}/uploads/content/update-multiple-files`,
			{
				method: 'POST',
				'Content-type': 'multipart/form-data',
				'x-amz-acl': 'public-read',
				body: formData,
			}
		);
		const response = await multiResponse.json();
		return response.data;
	};

	const handleUpdateContentDrafts = async () => {
		const {
			title,
			author,
			description,
			isWebReadable,
			isExclusive,
			isLoungeCover,
			priceValue,
			priceCurrency,
		} = values;
		try {
			setPriceObj({
				...priceObj,
				priceCurrency: priceCurrency?.value,
				priceValue: priceValue?.value,
				isWebReadable: isWebReadable?.value,
			});
			setPublishLoading(true);
			let bookUrl;
			let responseImageUrl = '';
			let responseBookUrl = '';
			/**
			 * If both file inputs have file Objects ready for upload
			 * we asimulate a multiple file upload approach to one Rest endpoint
			 *
			 */
			if (bookUrlFile && file) {
				let form = new FormData();
				form.append('bookUrl', bookUrlFile);
				form.append('imageUrl', file);
				let allFiles = await draftMultipleFileUpload(form);
				responseBookUrl = allFiles.bookUrl;
				responseImageUrl = allFiles.imageUrl;
			}

			if (bookUrlFile && !file) {
				let form = new FormData();
				form.append('bookUrl', bookUrlFile);
				responseBookUrl = await draftBookUpload(form);
			}

			if (!bookUrlFile && file) {
				let form = new FormData();
				form.append('imageUrl', file);
				responseImageUrl = await draftBookImageUpload(form);
			}

			let draftQuery = {
				title,
				author,
				description,
				priceCurrency: priceCurrency?.value,
				priceValue,
				isWebReadable: isWebReadable?.value,
				isExclusive: isExclusive?.value,
				isLoungeCover: isLoungeCover?.value,
				isPublished: false,
			};
			if (responseBookUrl) {
				draftQuery.bookUrl = responseBookUrl;
			}

			if (responseImageUrl) {
				draftQuery.imageUrl = responseImageUrl;
			}
			const graphResponse = await updateContentDraft({
				variables: {
					where: { id: { _eq: bookId } },
					set: draftQuery,
				},
			});
			setPublishLoading(false);
			const msgVar =
				graphResponse?.data?.update_contentDrafts?.returning[0]?.title;
			successNotify(`Successfully update ${msgVar}.`);
			window.location.reload();
		} catch (error) {
			setPublishLoading(false);
			errorNotify('Something went wrong, try again');
		}
	};

	const archiveContentFunc = async () => {
		const {
			title,
			description,
			priceValue,
			priceCurrency,
			isWebReadable,
			isExclusive,
			isLoungeCover,
			// bookUrl,
			author,
			exclusiveId,
		} = values;

		try {
			setDraftLoading(true);
			if (priceCurrency?.value) {
				setPriceObj({
					...priceObj,
					priceCurrency: priceCurrency?.value,
					isWebReadable: isWebReadable?.value,
				});
			}

			if (priceValue?.value) {
				setPriceObj({
					...priceObj,
					priceValue: priceValue?.value,
				});
			}

			const response = await archiveContent({
				variables: {
					objects: {
						title,
						author,
						description,
						imageUrl: book?.imageUrl,
						bookUrl: book?.bookUrl,
						priceCurrency: priceCurrency?.value,
						priceValue,
						userId: exclusiveId ? exclusiveId : null,
						isExclusive: isExclusive?.value,
						isLoungeCover: isLoungeCover?.value,
						isWebReadable: false,
						isPublished: false,
					},
				},
			});

			if (bookId && book?.isPublished) {
				await removeBook({
					variables: {
						bookId,
					},
				});
			} else {
				await removeContentDraft({
					variables: {
						contentDraftId: bookId,
					},
				});
			}

			setPublishedStatus('Draft');

			setDraftLoading(false);

			const msgVar = response?.data?.insert_archiveContent?.returning[0]?.title;

			successNotify(`Archived ${msgVar} successfully.`);

			history.push(isAdmin ? '/contents' : '/content-owner');
		} catch (error) {
			setDraftLoading(false);
			const err = error?.toString()?.split(':')[2];
			if (err && err.includes('unique')) {
				return errorNotify('This content is archived already');
			}
			errorNotify('Something went wrong, try again');
		}
	};

	const genre = book?.genre?.title ?? book?.genres[0]?.genre?.title;
	const lounge = book === undefined ? null : '';
	let YesOrNoSelection = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },
	];

	return (
		<Wrapper>
			{bookLoading | contentDraftLoading ? (
				<CustomSpinner />
			) : (
				<DetailLayoutView
					image={preview ? preview : book?.imageUrl}
					uploadName='Content'
					onChange={handleFile}
					update
					// imageLoading={loading || imageLoading}
					breadcrumbTitle='Content'
					breadcrumbItem={book?.title}
					breadcrumbRoute={isAdmin ? '/contents' : '/content-owner'}
				>
					<NewDetailView
						key1={{
							label: 'Content Title',
							placeholder: 'Content Title',
							name: 'title',
							status: 'edit',
							type: 'input',
							isEditable: true,
							value: book?.title,
						}}
						key2={{
							label: 'Content Author',
							placeholder: 'Content Author',
							name: 'author',
							status: 'edit',
							type: 'input',
							isEditable: true,
							value: book?.author,
						}}
						key3={{
							label: 'Content Description',
							placeholder: 'Content Description',
							name: 'description',
							status: 'edit',
							type: 'description',
							isEditable: true,
							value: book?.description,
						}}
						key4={{
							label: 'Upload Content',
							show: true,
							type: 'upload',
							handleUpload: handleContentUpload,
							status: 'edit',
						}}
						key5={{
							label: 'Genre',
							status: 'edit',
							type: 'text',
							value: genre,
						}}
						key6={
							book && book?.lounges?.length
								? {
										label: 'Lounge',
										status: 'edit',
										type: 'text',
										value: book?.lounges[0]?.lounge?.name,
								  }
								: null
						}
						key7={{
							label: 'Web Readable',
							name: 'isWebReadable',
							status: 'edit',
							show: true,
							type: genre === 'Articles' || 'Blogs'||'Articles'|| 'Magazines' ? 'select' : '',
							isEditable: true,
							value: book?.isWebReadable == true ? 'Yes' : 'No',
							options: YesOrNoSelection,
						}}
						key8={{
							label: 'Exclusive',
							name: 'isExclusive',
							status: 'edit',
							show: true,
							type: 'select',
							isEditable: true,
							value: book.isExclusive ? 'Yes' : 'No',
							options: YesOrNoSelection,
						}}
						key9={{
							label: 'Exclusive Lounge Cover',
							name: 'isLoungeCover',
							status: 'edit',
							show: true,
							type: 'select',
							isEditable: true,
							value: book.isLoungeCover ? 'Yes' : 'No',
							options: YesOrNoSelection,
						}}
						key10={{
							label: 'Status',
							status: 'edit',
							type: 'text',
							value: publishedStatus,
						}}
						key11={{
							label: 'Price Value',
							show: true,
							placeholder: 'Price Value',
							name: 'priceValue',
							status: 'edit',
							type: 'input',
							isEditable: true,
							value: priceObj?.priceValue
								? formatPrice(priceObj?.priceValue, priceObj?.priceCurrency)
								: 'FREE',
						}}
						key12={{
							label: 'Price Currency',
							show: true,
							placeholder: 'Price Currency',
							name: 'priceCurrency',
							status: 'edit',
							options: priceCurrencyOptions,
							type: 'select',
							isEditable: true,
							value: priceObj?.priceCurrency,
							// value: book?.priceCurrency?.toUpperCase(),
						}}
						key13={{
							label: 'Deep Link',
							show: true,
							placeholder: '',
							name: 'deepLink',
							status: 'edit',
							type: 'input',
							isEditable: true,
							value: book?.deepLink ?? 'N/A',
						}}
						key14={{
							label: 'Created At',
							status: 'edit',
							type: 'text',
							value: moment(book?.createdAt).fromNow(),
						}}
						key15={
							book &&
							book?.readStats?.length &&
							admin.role.name === 'SUPER-ADMIN'
								? {
									label: 'No. of reads',
									status: 'edit',
									type: 'text',
									value: book?.readStats[0]?.readCount,
								  }
								: null
						}
						key16={{
							label: 'Published By',
							status: 'edit',
							type: 'text',
							value: book?.publishedBy ?? 'Admin',
						}}
						action={{
							type: 'action',
							actionText: book?.isPublished
								? {
										btn1Text: 'Preview',
										btn2Text: 'Hide',
										btn3Text: 'Update',
										status: 'edit',
								  }
								: {
										btn1Text: 'Preview',
										btn2Text: 'Show',
										btn3Text: 'Publish',
										btn4Text: 'Update',
										status: 'edit',
								  },
							action: {
								btn2: () => setDraftModal(true),
								btn3: () =>
									book?.isPublished
										? setUpdateModal(true)
										: setPublishModal(true),
								btn4: () => setUpdateModal(true),
								status: 'edit',
								book,
							},
							// actionLoading: {
							//   btn2Loading:
							//     archiveContentLoading ||
							//     removeContentDraftLoading ||
							//     removeBookLoading ||
							//     draftLoading,
							//   btn3Loading: publishLoading,
							// },
						}}
						previewUrl={book?.bookUrl}
						values={values}
						setValues={setValues}
					/>
				</DetailLayoutView>
			)}

			{/* publish publishModal */}
			<Modal isOpen={publishModal} centered={true}>
				<ModalBody>
					<p>Are you sure you want to publish {values.title?.toUpperCase()}</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setPublishModal(false)}>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={() => {
							handleUpdateContent();
							setPublishModal(false);
						}}
					>
						{publishLoading ? (
							<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			{/* publish publishModal */}
			<Modal isOpen={updateModal} centered={true}>
				<ModalBody>
					<p>Are you sure you want to update {values.title?.toUpperCase()}</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setUpdateModal(false)}>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={() => {
							if (book?.isPublished) {
								handleUpdateContent();
								setUpdateModal(false);
							} else {
								handleUpdateContentDrafts();
								setUpdateModal(false);
							}
						}}
					>
						{archiveContentLoading ||
						removeContentDraftLoading ||
						removeBookLoading ||
						draftLoading ? (
							<Spinner style={{ width: 12, height: 12, marginRight: 10 }} />
						) : (
							'Yes'
						)}
					</Button>
				</ModalFooter>
			</Modal>

			{/* hide/draft publishModal */}
			<Modal isOpen={draftModal} centered={true}>
				<ModalBody>
					<p>Are you sure you want to hide {values.title?.toUpperCase()}</p>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => setDraftModal(false)}>
						No
					</Button>{' '}
					<Button
						color='primary'
						onClick={() => {
							archiveContentFunc();
							setDraftModal(false);
						}}
					>
						Yes
					</Button>
				</ModalFooter>
			</Modal>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	height: calc(100vh - 10vh);
	margin-top: 10vh;
	overflow-y: auto;
`;

export default NewBookDetail;
